import AuthProvider from '../aad/AuthProvider'
import { BASEURL } from '../config'
import ApiErrorModel, { ApiErrorIncosistency } from '../models/ApiErrorModel'

export const fetchApiCustom = async (url: string, options?: RequestInit) => {
  const accessTokenResponse = await AuthProvider.getAccessToken()
  const headers: HeadersInit = new Headers()
  headers.set('Content-Type', 'application/json')
  headers.set('Authorization', 'Bearer ' + accessTokenResponse.accessToken)

  try {
    const res = await fetch(BASEURL + url, { headers, ...options })
    if (res.status === 200) {
      return res
    } else {
      const errorResponse = (await res.json()) as ApiErrorModel
      return Promise.reject({ error_code: res.status, error_message: errorResponse.error_message } as ApiErrorModel)
    }
  } catch (error) {
    return Promise.reject({
      error_code: 500,
      error_message: ''
    } as ApiErrorModel)
  }
}

export const fetchApiProcessTemplate = async (url: string, options?: RequestInit) => {
  const accessTokenResponse = await AuthProvider.getAccessToken()
  const headers: HeadersInit = new Headers()
  headers.set('Authorization', 'Bearer ' + accessTokenResponse.accessToken)

  try {
    const res = await fetch(BASEURL + url, { headers, ...options })
    if (res.status === 200) {
      return res
    } else {
      const errorResponse = (await res.json()) as ApiErrorIncosistency
      return Promise.reject({
        error_code: res.status,
        error_message: errorResponse.error_message,
        inconsitencies: errorResponse.inconsitencies
      } as ApiErrorIncosistency)
    }
  } catch (error) {
    return Promise.reject({
      error_code: 500,
      error_message: ''
    } as ApiErrorModel)
  }
}

const fetchApi = async (url: string, options?: RequestInit) => {
  return (await fetchApiCustom(url, options)).json()
}

export default fetchApi
