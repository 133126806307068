import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

interface IConfimationMessageProps {
  open: boolean
  setOpen(open: boolean): void
  onClickAccept(): void
  title: string
  content: string
}

const ConfimationMessage: React.FC<IConfimationMessageProps> = props => {
  const handleClose = () => {
    props.setOpen(false)
  }
  const handleAccept = () => {
    props.onClickAccept()
    handleClose()
  }
  return (
    <Dialog open={props.open} onClose={handleClose} data-testid='alert'>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant='contained'
          color='error'
          data-testid='button-alert-cancelar'
          startIcon={<CloseIcon />}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleAccept}
          variant='contained'
          autoFocus
          sx={{ marginLeft: 2 }}
          data-testid='button-alert-accept'
          startIcon={<CheckIcon />}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfimationMessage
