import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, CardHeader, IconButton, Theme } from '@mui/material'
import { green } from '@mui/material/colors'
import { createStyles, makeStyles } from '@mui/styles'
import EnvironmentModel from '../models/EnvironmentModel'
import { IStateRouter } from './companyPanelComponents/CompanyPanel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3)
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    health: {
      color: green[500]
    }
  })
)

export interface EnvironmentCardProps {
  environment: EnvironmentModel
}

const EnvironmentCard: React.SFC<EnvironmentCardProps> = ({ environment }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const state = useLocation().state as IStateRouter
  const goToLicenses = () => {
    navigate(`${environment.name}/licenses`, { state: { ...state, environmentName: environment.key } })
  }

  return (
    <Card
      data-testid={'env-card-' + environment.key}
      className={classes.root}
      variant='elevation'
      onClick={() => {
        goToLicenses()
      }}
    >
      <CardHeader
        avatar={
          <IconButton aria-label='licences'>
            <EventAvailableIcon color='primary' />
          </IconButton>
        }
        title={environment.name}
        subheader={environment.key}
      />
    </Card>
  )
}

export default EnvironmentCard
