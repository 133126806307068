import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import ErrorIcon from '@mui/icons-material/Error'
import { red } from '@mui/material/colors'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import * as XLSX from 'xlsx'
import InconsistencyModel, { InconsistencyView } from '../../models/InconsistencyModel'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

const addExtensionToFilename = (filename: string): string => {
  const extension = '.xlsx'

  if (!filename.toLowerCase().endsWith(extension)) {
    return filename + extension
  }

  return filename
}

export interface IViewInconsistenciesProps {
  inconsistencies: InconsistencyModel[]
  clearData: () => void
  fileName: string
}

const convertColumnNumberToLetter = (columnNumber: number): string => {
  let columnName = ''

  while (columnNumber > 0) {
    const remainder = (columnNumber - 1) % 26
    const letter = String.fromCharCode(65 + remainder)
    columnName = letter + columnName
    columnNumber = Math.floor((columnNumber - 1) / 26)
  }

  return columnName
}

const exportToCSV = (csvData: any[], filename: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', addExtensionToFilename(filename))
  document.body.appendChild(link)
  link.click()
}

const ViewInconsistencies: React.FC<IViewInconsistenciesProps> = ({ inconsistencies, clearData, fileName }) => {
  const getInconsistenciesView = (): InconsistencyView[] => {
    return inconsistencies.map(model => {
      return {
        record: model.record,
        column: convertColumnNumberToLetter(model.column),
        type: model.type,
        inconsistency: model.inconsistency
      }
    })
  }

  return (
    <>
      <Toolbar sx={{ with: '100%', pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
        <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
          Inconsistencias Encontradas
        </Typography>
        <Tooltip
          data-testid='button-clear'
          title='Limpiar'
          onClick={() => {
            clearData()
          }}
        >
          <IconButton>
            <CleaningServicesIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title='Descargar Incosistencias'
          data-testid='button-download-incosistencies'
          onClick={() => {
            exportToCSV(getInconsistenciesView(), `inconsistencias_${fileName}`)
          }}
        >
          <IconButton>
            <DownloadForOfflineIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Registro</TableCell>
              <TableCell align='right'>Columna</TableCell>
              <TableCell align='right'>Tipo</TableCell>
              <TableCell align='right'>Inconsistencia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getInconsistenciesView().map(row => (
              <TableRow key={row.record} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='right'>
                  <ErrorIcon sx={{ color: red[500], marginRight: 1 }} />
                  {row.record}
                </TableCell>
                <TableCell align='right'>{row.column}</TableCell>
                <TableCell align='right'>{row.type}</TableCell>
                <TableCell align='right'>{row.inconsistency}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ViewInconsistencies
