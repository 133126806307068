/* eslint-disable max-lines-per-function */
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  useTheme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React, { Fragment, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLicensesContext } from '../../ContextProviders/LicensesProvider'
import useMyMediaQuery from '../../hooks/useMyMediaQuery'
import LicensesModel from '../../models/LicensesModel'
import BackDropLoad from '../backDropLoadComponents/BackDropLoad'
import { IStateRouter } from '../companyPanelComponents/CompanyPanel'
import ConfimationMessage from '../ConfirmationMessageComponents/ConfimationMessage'
import HeadLicenses from './HeadLicenses'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: 20
  },
  item: {
    borderBottom: '1px solid ' + theme.palette.divider,
    flexGrow: 1,
    marginTop: 20,
    padding: 10
  },
  btn: {
    float: 'right',
    margin: theme.spacing(1)
  },
  table: {
    minWidth: 750
  }
}))

interface IHeadCell {
  id: keyof LicensesModel
  label: string
}

const columns: IHeadCell[] = [
  { id: 'is_active', label: 'Activo' },
  { id: 'entity_id', label: 'ID' },
  { id: 'entity_name', label: 'Entidad' },
  { id: 'save_expiration_date', label: 'Lic guardar expira' },
  { id: 'print_expiration_date', label: 'Lic imprimir expira' },
  { id: 'entity_id', label: 'Editar' }
]

const inlineHeader = (heading: string, _isXS: boolean) => {
  return (
    <Fragment>
      {_isXS && (
        <strong>
          {heading}
          <br />
        </strong>
      )}
    </Fragment>
  )
}

function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export interface LicensesProps {}

const Licenses: React.FC<LicensesProps> = () => {
  const { licenses, getLicenses, isLoading, changeEntityEnabledState } = useLicensesContext()
  const [licensesFilters, setLicensesFilters] = React.useState<LicensesModel[]>([])
  const [viewLicensesActives, setViewLicensesActives] = React.useState(true)
  const [openMessageConfirmation, setMessageConfirmation] = React.useState(false)
  const [entityIdToModify, setEntityIdToModify] = React.useState(0)
  const [entityStateToModify, setEntityStateToModify] = React.useState(false)

  const filterActiveLicenses = () => {
    return licenses.filter(license => license.is_active)
  }

  const filterInactiveLicenses = () => {
    return licenses.filter(license => !license.is_active)
  }
  const { environmentKey } = useParams<{ environmentKey: string }>()

  const [orderBy, setOrderBy] = React.useState<keyof LicensesModel>('entity_name')
  const [order, setOrder] = React.useState<Order>('asc')

  const theme = useTheme()
  const isXS = useMyMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const navigate = useNavigate()

  const state = useLocation().state as IStateRouter

  const goToDashboard = (company: LicensesModel) => {
    navigate(`/${environmentKey || ''}/${company.entity_id}/panel`, { state: { ...state, company } })
  }

  const calculateColor = (date: Date | null): string => {
    if (!date) return theme.palette.text.primary

    const today = moment()

    if (date <= today.toDate()) return theme.palette.error.main

    if (date <= today.add(15, 'd').toDate()) return theme.palette.warning.main

    return theme.palette.text.primary
  }

  function stableSort(array: LicensesModel[], comparator: (a: LicensesModel, b: LicensesModel) => number) {
    const stabilizedThis = [...licensesFilters]
    stabilizedThis.sort((a, b) => {
      const resultComparator = comparator(a, b)
      if (resultComparator !== 0) return resultComparator

      return 1
    })
    return stabilizedThis
  }

  const handleRequestSort = (property: keyof LicensesModel) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>, entityId: number) => {
    setEntityIdToModify(entityId)
    setEntityStateToModify(event.target.checked)
    setMessageConfirmation(true)
  }

  const handleActionConfirmation = () => {
    changeEntityEnabledState(environmentKey ?? '', entityIdToModify, entityStateToModify)
  }

  useEffect(() => {
    getLicenses(environmentKey ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (viewLicensesActives) {
      setLicensesFilters(filterActiveLicenses())
    } else {
      setLicensesFilters(filterInactiveLicenses())
    }
  }, [viewLicensesActives, licenses])

  return (
    <>
      <ConfimationMessage
        content={'¿Está seguro de que desea modificar el estado de la entidad?'}
        onClickAccept={handleActionConfirmation}
        title={'Modificar Estado Entidad'}
        open={openMessageConfirmation}
        setOpen={setMessageConfirmation}
        data-testid={'test-confirmation-message'}
      />

      <Container>
        <Box mt={1}>
          <BackDropLoad open={isLoading} />
          <Paper className={classes.paper}>
            <HeadLicenses environmentKey={environmentKey ?? ''} {...{ viewLicensesActives, setViewLicensesActives }} />
            {!isXS && (
              <Grid data-testid='header-grid-licenses' container className={classes.root} spacing={2}>
                <TableContainer>
                  <Table className={classes.table} aria-labelledby='tableTitle' aria-label='enhanced table'>
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell sortDirection={'asc'} key={column.label}>
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={() => handleRequestSort(column.id)}
                              data-testid={'head-table-' + column.id}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(licensesFilters, getComparator(order, orderBy)).map(license => (
                        <TableRow
                          role='checkbox'
                          tabIndex={-1}
                          key={license.entity_id}
                          data-testid={'license-' + license.entity_id}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={license.is_active}
                              color='primary'
                              onChange={event => handleChangeState(event, Number(license.entity_id))}
                            />
                          </TableCell>
                          <TableCell align='left' data-testid={'cell-table-id'}>
                            {license.entity_id}
                          </TableCell>
                          <TableCell align='left'>{license.entity_name}</TableCell>
                          <TableCell align='left'>
                            <span style={{ color: calculateColor(license.save_expiration_date as Date | null) }}>
                              {license.save_expiration_date?.toLocaleString() ?? ''}
                            </span>
                          </TableCell>
                          <TableCell align='left'>
                            <span style={{ color: calculateColor(license.save_expiration_date as Date | null) }}>
                              {license.print_expiration_date?.toLocaleString() ?? ''}
                            </span>
                          </TableCell>
                          <TableCell align='left'>
                            <Button
                              data-testid={'btn-config-' + license.entity_id}
                              variant='outlined'
                              color='primary'
                              size='small'
                              onClick={() => {
                                goToDashboard(license)
                              }}
                            >
                              Configurar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {isXS &&
              licensesFilters.map(l => (
                <Grid
                  data-testid={'license-' + l.entity_id}
                  key={l.entity_id}
                  container
                  className={classes.item}
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} md={1}>
                    {inlineHeader('Activo', isXS)}
                    <Checkbox checked={l.is_active} name={l.entity_id.toString()} color='primary' />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    {inlineHeader('Id', isXS)}
                    <span>{l.entity_id}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {inlineHeader('Entidad', isXS)}
                    <span>{l.entity_name}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {inlineHeader('Lic guardar expira', isXS)}
                    <span style={{ color: calculateColor(l.save_expiration_date as Date | null) }}>
                      {l.save_expiration_date?.toLocaleString() ?? ''}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {inlineHeader('Lic imprimir expira', isXS)}
                    <span style={{ color: calculateColor(l.save_expiration_date as Date | null) }}>
                      {l.print_expiration_date?.toLocaleString() ?? ''}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <Button
                      onClick={() => {
                        goToDashboard(l)
                      }}
                      data-testid={'btn-edit-' + l.entity_id}
                      variant='outlined'
                      color='primary'
                      size='small'
                    >
                      Configurar
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Paper>
        </Box>
      </Container>
    </>
  )
}

export default Licenses
