import { Box, Button, Container, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import React, { Fragment } from 'react'
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import authProvider from '../aad/AuthProvider'
import Environments from '../components/Environments'
import Licenses from '../components/licensesComponents/Licenses'
import Notification from '../components/Notification'
import { EnvironmentsProvider } from '../ContextProviders/EnvironmentsProvider'
import { LicensesProvider } from '../ContextProviders/LicensesProvider'
import { NotificationProvider } from '../ContextProviders/NotificationProvider'
import CompanyPanel from '../components/companyPanelComponents/CompanyPanel'
import CreateEntity from './CreateEntity'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: theme.palette.primary.main,
    color: 'white',
    textDecoration: 'none'
  },
  logout: {
    color: 'white !important',
    float: 'right',
    right: theme.spacing(3)
  },
  logoutbtn: {
    color: 'white'
  }
}))

export interface MainProps {}

const Main: React.SFC<MainProps> = () => {
  const classes = useStyles()

  return (
    <Fragment>
      <Router>
        <NotificationProvider>
          <Box px={3} py={2} className={classes.header}>
            <Container>
              <Button className={classes.logout} onClick={authProvider.logout} endIcon={<ExitToAppIcon />}>
                Salir
              </Button>
              <Typography data-testid='toolbar-title' variant='h6' noWrap>
                <Link className={classes.header} to='/'>
                  Application Admin
                </Link>
              </Typography>
            </Container>
          </Box>
          <Notification />
          <Routes>
            <Route
              path='/:environmentKey/licenses'
              element={
                <LicensesProvider>
                  <Licenses />
                </LicensesProvider>
              }
            />
            <Route
              path='/:environmentKey/:entityId/panel'
              element={
                <LicensesProvider>
                  <CompanyPanel />
                </LicensesProvider>
              }
            />
            <Route
              path='/:environmentKey/:entityId/panel/*'
              element={
                <LicensesProvider>
                  <CompanyPanel />
                </LicensesProvider>
              }
            />
            <Route path='/:environmentKey/create' element={<CreateEntity />} />
            <Route
              path='/'
              element={
                <EnvironmentsProvider>
                  <Environments />
                </EnvironmentsProvider>
              }
            />
          </Routes>
        </NotificationProvider>
      </Router>
    </Fragment>
  )
}

export default Main
