import { Box, Container } from '@mui/material'
import React from 'react'
import { useEnvironmentsContext } from '../ContextProviders/EnvironmentsProvider'
import BreakpointMasonry from './BreakpointMasonry'
import EnvironmentCard from './EnvironmentCard'
import BackDropLoad from './backDropLoadComponents/BackDropLoad'

export interface EnvironmentsProps {}

const Environments: React.FC<EnvironmentsProps> = () => {
  const { environments, getEnvironments, isLoading } = useEnvironmentsContext()

  React.useEffect(() => {
    getEnvironments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container data-testid='main-container'>
      <Box mt={1}>
        <BackDropLoad open={!isLoading} />
        <BreakpointMasonry>
          {environments.map(e => (
            <EnvironmentCard key={e.key} environment={e} />
          ))}
        </BreakpointMasonry>
      </Box>
    </Container>
  )
}

export default Environments
