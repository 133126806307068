import CityModel from './CityModel'

export interface IEntityModel {
  first_name: string
  second_name: string
  last_name: string
  second_last_name: string
  email: string
  business_name: string
  small_name: string
  activity_type_id: number
  branch_office_name: string
  document_type_id: number
  document_number: string
  system_type_id: number
  person_type_id: number
  place_id: number
  branch_office_address: string
  branch_office_phone: string
}

export interface EntityModel {
  name: string
  business_group_id: number
  entity_id: number
  branch_office_id: number
}

export interface ICreateEntityState {
  userState: IUserState
  entityState: IEntityState
  branchOfficeState: IBranchOfficeState
}

export interface IUserState {
  firstName: CreateEntityValue
  secondName: CreateEntityValue
  lastName: CreateEntityValue
  secondLastName: CreateEntityValue
  email: CreateEntityValue
}

export interface IEntityState {
  businessName: CreateEntityValue
  shortName: CreateEntityValue
  economicActivityId: CreateEntityValue
  documentTypeId: CreateEntityValue
  documentNumber: CreateEntityValue
  natureId: CreateEntityValue
  regimenId: CreateEntityValue
}

export interface IBranchOfficeState {
  name: CreateEntityValue
  city: CreateEntityCity
  address: CreateEntityValue
  telephone: CreateEntityValue
}

export interface CreateEntityValue extends ICreateEntity {
  value: string | number
}

interface ICreateEntity {
  helperText: string
  required: boolean
  error: boolean
}

export interface CreateEntityCity extends ICreateEntity {
  value: CityModel | null
}

export const defaultUserState: IUserState = {
  firstName: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  secondName: {
    error: false,
    helperText: '',
    required: false,
    value: ''
  },
  lastName: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  secondLastName: {
    error: false,
    helperText: '',
    required: false,
    value: ''
  },
  email: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  }
}

export const defaultEntityState: IEntityState = {
  businessName: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  shortName: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  economicActivityId: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  documentTypeId: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  documentNumber: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  natureId: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  regimenId: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  }
}

export const defaultBranchOfficeState: IBranchOfficeState = {
  address: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  city: {
    error: false,
    helperText: '',
    required: true,
    value: null
  },
  name: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  },
  telephone: {
    error: false,
    helperText: '',
    required: true,
    value: ''
  }
}
