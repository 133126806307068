import { Button } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButtonComponent: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Button
      variant='contained'
      size='small'
      startIcon={<KeyboardBackspaceIcon />}
      data-testid='button-back'
      color='primary'
      onClick={() => navigate(-1)}
      sx={{ mb: '0.5rem' }}
    >
      REGRESAR
    </Button>
  )
}

export default BackButtonComponent
