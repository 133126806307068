import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@mui/material'
import React from 'react'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import OtherHousesIcon from '@mui/icons-material/OtherHouses'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups'
import LicensesModel from '../../models/LicensesModel'
import CompanyServicesConfigurator from '../companyServicesConfigurator/CompanyServicesConfigurator'
import UpdateLicense from '../UpdateLicense'
import { useLicensesContext } from '../../ContextProviders/LicensesProvider'
import BackDropLoad from '../backDropLoadComponents/BackDropLoad'
import BulkDataUploads from '../bulkDataUploadsComponents/BulkDataUploads'
import { ExcelTemplateProvider } from '../../ContextProviders/ExcelTemplateProvider'
import BackButtonComponent from '../BackButtonComponent/BackButton'

export interface IStateRouter {
  company: LicensesModel
  environmentName: string
}
const CompanyPanel: React.FC = () => {
  const state = useLocation().state as IStateRouter
  const { environmentKey } = useParams<{ environmentKey: string }>()
  const [panelIsOpen, SetPanelIsOpen] = React.useState(false)
  const { updateLicense, isLoadingUpdate } = useLicensesContext()

  const [company, SetCompany] = React.useState(state.company)

  const updateLicensesPanel = () => {
    SetPanelIsOpen(!panelIsOpen)
  }

  return (
    <>
      <BackDropLoad open={isLoadingUpdate} />
      <Paper elevation={3} sx={{ my: { xs: 3, md: 2 }, p: { xs: 2, md: 2 } }}>
        <Container component='main' maxWidth='lg'>
          <CssBaseline />
          <Box
            sx={{
              margin: 1,
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={0}>
              <Grid item xs={2}>
                <BackButtonComponent />
              </Grid>
              <Grid item xs={8}>
                <Typography component='h2' variant='h5' align='center'>
                  {`PANEL DE CONFIGURACIÓN`}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={0}>
              <Grid item xs={2}>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <OtherHousesIcon color={'primary'} />
                    </ListItemAvatar>
                    <ListItemText
                      primary='Ambiente'
                      data-testid={'test-enviroment-name'}
                      secondary={environmentKey || ''}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={5}>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <LocalHospitalIcon color={'error'} />
                    </ListItemAvatar>
                    <ListItemText primary='Entidad' secondary={company.entity_name} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={2}>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <GroupsIcon color={'warning'} />
                    </ListItemAvatar>
                    <ListItemText primary='ID Grupo Empresarial' secondary={company.business_group_id} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={2}>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <LocalHospitalIcon color={'info'} />
                    </ListItemAvatar>
                    <ListItemText primary='ID Entidad' secondary={company.entity_id} />
                  </ListItem>
                </List>
              </Grid>
              <Grid container item xs={12} direction='row' justifyContent='center' alignItems='center' spacing={2}>
                <Routes>
                  <Route path='/'>
                    <Route index element={<CompanyServicesConfigurator {...{ updateLicensesPanel, company }} />} />
                    <Route
                      path='bulk/data'
                      element={
                        <ExcelTemplateProvider>
                          <BulkDataUploads />
                        </ExcelTemplateProvider>
                      }
                    />
                  </Route>
                </Routes>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
      <UpdateLicense
        isOpen={panelIsOpen}
        togglePanel={updateLicensesPanel}
        license={company}
        setLicense={SetCompany}
        updateLicense={(license: LicensesModel) => {
          updateLicense(license, environmentKey ?? '')
        }}
      />
    </>
  )
}

export default CompanyPanel
