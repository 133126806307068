import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

interface IBackDropLoadApiProps {
  open: boolean
}

const BackDropLoad: React.FC<IBackDropLoadApiProps> = ({ open }) => {
  return (
    <Backdrop
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        color: '#fff'
      }}
      open={open}
      data-testid={'test-backdrop'}
    >
      <CircularProgress color='inherit' size={100} />
    </Backdrop>
  )
}

export default BackDropLoad
