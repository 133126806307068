// authProvider.js
import { AuthenticationParameters, Configuration } from 'msal'
import { LoginType, MsalAuthProvider } from 'react-aad-msal'
import 'regenerator-runtime/runtime'
import { APISCOPE, AUTHORITY, CLIENTID, REDIRECT } from '../config'

// Msal Configurations
const config = {
  auth: {
    authority: AUTHORITY,
    clientId: CLIENTID,
    redirectUri: REDIRECT
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
} as Configuration

// Authentication Parameters
const authenticationParameters = {
  scopes: ['openid', 'profile', APISCOPE]
} as AuthenticationParameters

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/refresh'
}

const authProvider = new MsalAuthProvider(config, authenticationParameters, options)

export default authProvider
