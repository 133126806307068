import { Alert, Box, Container } from '@mui/material'
import * as React from 'react'
import { useNotificationContext } from '../ContextProviders/NotificationProvider'

export interface NotificationProps {}

const Notification: React.SFC<NotificationProps> = () => {
  const { notification } = useNotificationContext()

  return (
    <React.Fragment>
      {notification && (
        <Container>
          <Box mt={1} mb={1}>
            <Alert severity={notification.level}>{notification.message}</Alert>
          </Box>
        </Container>
      )}
    </React.Fragment>
  )
}

export default Notification
