import { useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Masonry from 'react-masonry-css'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2, 0)
  },
  paper: {
    marginBottom: theme.spacing(4)
  },
  masonryGrid: {
    display: 'flex',
    marginLeft: theme.spacing(-4),
    width: 'inherit'
  },
  masonryColumn: {
    paddingLeft: theme.spacing(4),
    backgroundClip: 'padding-box'
  }
}))

export interface BreakpointMasonryProps {
  children: React.ReactNode
}

const BreakpointMasonry: React.SFC<BreakpointMasonryProps> = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()

  const breakpointCols = {
    default: 3,
    [theme.breakpoints.values.xl]: 3,
    [theme.breakpoints.values.lg]: 3,
    [theme.breakpoints.values.md]: 2,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1
  }

  return (
    <Masonry breakpointCols={breakpointCols} className={classes.masonryGrid} columnClassName={classes.masonryColumn}>
      {children}
    </Masonry>
  )
}

export default BreakpointMasonry
