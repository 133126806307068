import React from 'react'
import fetchApi from '../api/fetchApi'
import ApiErrorModel from '../models/ApiErrorModel'
import { EntityModel, ICreateEntityState, IEntityModel } from '../models/CreateEntityModel'
import { useNotificationContext } from '../ContextProviders/NotificationProvider'

export const useEntity = (enviromentkey: string) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [result, setResult] = React.useState<EntityModel | null>(null)
  const { setNotification } = useNotificationContext()

  const buildEntityRequest = (createEntityState: ICreateEntityState) => {
    return {
      first_name: createEntityState.userState.firstName.value.toString().trim(),
      second_name: createEntityState.userState.secondName.value.toString().trim(),
      last_name: createEntityState.userState.lastName.value.toString().trim(),
      second_last_name: createEntityState.userState.secondLastName.value.toString().trim(),
      email: createEntityState.userState.email.value.toString().trim(),
      business_name: createEntityState.entityState.businessName.value.toString().trim(),
      small_name: createEntityState.entityState.shortName.value.toString().trim(),
      activity_type_id: createEntityState.entityState.economicActivityId.value as number,
      document_type_id: createEntityState.entityState.documentTypeId.value as number,
      document_number: createEntityState.entityState.documentNumber.value.toString().trim(),
      system_type_id: createEntityState.entityState.regimenId.value as number,
      person_type_id: createEntityState.entityState.natureId.value as number,
      branch_office_name: createEntityState.branchOfficeState.name.value.toString().trim(),
      place_id: createEntityState.branchOfficeState.city.value?.id as number,
      branch_office_address: createEntityState.branchOfficeState.address.value.toString().trim(),
      branch_office_phone: createEntityState.branchOfficeState.telephone.value.toString().trim()
    } as IEntityModel
  }
  const createEntity = (createEntityState: ICreateEntityState) => {
    setIsLoading(true)
    setSuccess(false)

    const newEntity = buildEntityRequest(createEntityState)

    const params: RequestInit = {
      method: 'POST',
      body: JSON.stringify(newEntity)
    }

    fetchApi(`api/v1/environments/${enviromentkey}/entities`, params).then(
      (entity: EntityModel) => {
        setNotification({
          message: `La entidad ${entity.name} se ha creado correctamente`,
          level: 'success'
        })

        setIsLoading(false)
        setSuccess(true)
        setResult(entity)
      },
      (error: ApiErrorModel) => {
        setNotification({
          message: error.error_message,
          level: 'error'
        })

        setIsLoading(false)
        setSuccess(false)
        setResult(null)
      }
    )
  }

  return { createEntity, buildEntityRequest, isLoading, success, result }
}
