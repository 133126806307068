import moment from 'moment'
import React, { createContext, useContext, useState } from 'react'
import fetchApi from '../api/fetchApi'
import ApiErrorModel from '../models/ApiErrorModel'
import LicensesModel from '../models/LicensesModel'
import { useNotificationContext } from './NotificationProvider'

export const dateFormatLicense = 'YYYY/MM/DD'

interface LicensesProviderModel {
  licenses: LicensesModel[]
  getLicenses: (env: string) => void
  updateLicense: (license: LicensesModel, environmentKey: string) => void
  isLoading: boolean
  isLoadingUpdate: boolean
  changeEntityEnabledState: (environmentKey: string, entityId: number, state: boolean) => void
}

const LicensesContext = createContext<LicensesProviderModel>({
  licenses: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLicenses: (env: string) => {
    /* do nothing */
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateLicense: (license: LicensesModel, environmentKey: string) => {
    /* do nothing */
  },
  isLoading: false,
  isLoadingUpdate: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeEntityEnabledState: (environmentKey: string, entityId: number, state: boolean) => {
    return
  }
})

export const useLicensesContext = () => useContext(LicensesContext)

export interface LicensesProviderProps {
  children: React.ReactNode
}

export const LicensesProvider: React.SFC<LicensesProviderProps> = ({ children }) => {
  const [licenses, setLicenses] = useState([] as LicensesModel[])
  const { setNotification } = useNotificationContext()
  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false)

  const setLicensesError = (message = 'Ha ocurrido un error, intente recarga la página') => {
    setNotification({ message, level: 'error' })
  }

  const setLicensesInfo = (count: number, envKey: string) => {
    setNotification({
      message: 'El ambiente ' + envKey + ' posee ' + count.toString() + ' licencias.',
      level: 'info'
    })
  }

  const getLicenses = (environmentKey: string) => {
    setIsLoading(true)
    fetchApi('api/v1/environments/' + environmentKey + '/licenses')
      .then(
        (res: LicensesModel[]) => {
          res.forEach(lic => {
            lic.save_expiration_date = transformDate(lic.save_expiration_date as string)
            lic.print_expiration_date = transformDate(lic.print_expiration_date as string)
          })
          setLicenses(res)
          setLicensesInfo(res.length, environmentKey)
        },
        (error: ApiErrorModel) => {
          error.error_message =
            'Ha ocurrido un error al obtener las licencias. Es posible que el ambiente ' +
            environmentKey +
            ' no tenga una cadena de conexión válida.'
          setLicensesError(error.error_message)
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateLicense = (license: LicensesModel, environmentKey: string) => {
    setIsLoadingUpdate(true)
    fetchApi('api/v1/environments/' + environmentKey + '/licenses', {
      method: 'PUT',
      body: JSON.stringify(license)
    })
      .then(
        (res: LicensesModel) => {
          const index = licenses.findIndex(l => l.entity_id === license.entity_id)

          res.save_expiration_date = transformDate(res.save_expiration_date as string)
          res.print_expiration_date = transformDate(res.print_expiration_date as string)
          licenses[index] = res

          setLicenses([...licenses])
          setNotification({
            message: 'La licencia ' + res.entity_name + ' fué actualizada satisfactoriamente.',
            level: 'success'
          })
        },
        (error: ApiErrorModel) => {
          error.error_message =
            'Ha ocurrido un error al intentar actualizar las licencias para la entidad: ' +
            license.entity_name +
            '. Mensaje de error: ' +
            error.error_message
          setLicensesError(error.error_message)
        }
      )
      .finally(() => {
        setIsLoadingUpdate(false)
      })
  }

  const changeEntityEnabledState = (environmentKey: string, entityId: number, state: boolean) => {
    setIsLoadingUpdate(true)
    fetchApi(`api/v1/environments/${environmentKey}/entities/${entityId}/changeState/`, {
      method: 'PUT',
      body: JSON.stringify(state)
    })
      .then(res => {
        if (!((res as unknown) as ApiErrorModel).error_message) {
          setNotification({
            message: `La entidad ${entityId} fué actualizada satisfactoriamente.`,
            level: 'success'
          })
          getLicenses(environmentKey)
        } else {
          throw Error(((res as unknown) as ApiErrorModel).error_message)
        }
      })
      .catch((error: Error) => {
        const error_message = `Ha ocurrido un error al intentar actualizar el estado para la entidad:
          ${entityId} Mensaje de error: ${error.message}`

        setLicensesError(error_message)
      })
      .finally(() => {
        setIsLoadingUpdate(false)
      })
  }

  return (
    <LicensesContext.Provider
      value={{ licenses, getLicenses, updateLicense, isLoading, isLoadingUpdate, changeEntityEnabledState }}
    >
      {children}
    </LicensesContext.Provider>
  )
}

const transformDate = (dateString: string) => {
  if (dateString !== '' && dateString !== null) {
    const date = moment(dateString, dateFormatLicense)
    if (date.isValid()) return date.toDate()
    return null
  }
  return null
}
