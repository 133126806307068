/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const AUTHORITY: string = (window as any)._env_AUTHORITY ?? ''
const CLIENTID: string = (window as any)._env_CLIENTID ?? ''
const REDIRECT: string = (window as any)._env_REDIRECT ?? ''
const APISCOPE: string = (window as any)._env_APISCOPE ?? ''
const BASEURL: string = (window as any)._env_BASEURL ?? ''

export { AUTHORITY, CLIENTID, REDIRECT, APISCOPE, BASEURL }
