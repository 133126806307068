import { AppBar, Box, Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import ExcelTemplateDownload from './ExcelTemplateDownload'
import TabPanel from './TabPanel'
import ExcelTemplateProcess from './ExcelTemplateProcess'

const BulkDataUploads: React.FC = () => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  return (
    <>
      <Paper elevation={12} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <AppBar position='static'>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='secondary'
              textColor='inherit'
              variant='fullWidth'
              aria-label='full width tabs example'
            >
              <Tab label='Descargar Plantilla' {...a11yProps(0)} />
              <Tab label='Procesar Plantilla' {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ExcelTemplateDownload />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ExcelTemplateProcess />
          </TabPanel>
        </Box>
      </Paper>
    </>
  )
}

export default BulkDataUploads
