import {
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid,
  Box,
  Theme,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Fab
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import StepCreateAndCommit from '../components/createEntityComponents/StepCreateAndCommit'
import StepDataBranchOffice from '../components/createEntityComponents/StepDataBranchOffice'
import StepDataEntity from '../components/createEntityComponents/StepDataEntity'
import StepDataUser from '../components/createEntityComponents/StepDataUser'
import { useEntity } from '../hooks/useEntity'
import {
  CreateEntityCity,
  CreateEntityValue,
  defaultBranchOfficeState,
  defaultEntityState,
  defaultUserState,
  IBranchOfficeState,
  IEntityState,
  IUserState
} from '../models/CreateEntityModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    top: '3vh',
    padding: theme.spacing(3),
    background: 'rgba(255, 255, 255, 0.8);',
    textAlign: 'center'
  },
  title: {
    paddingTop: theme.spacing(4),
    color: theme.palette.primary.main
  },
  button: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(15)
  },
  buttonContent: {
    padding: theme.spacing(2, 8, 2, 8)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  box: {
    margin: theme.spacing(0.5, 5)
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  }
}))

const checkCreateEntity = <T extends CreateEntityCity | CreateEntityValue>(field: T): [T, boolean] => {
  let error = false
  if (field.required && (field.value === '' || field.value === null)) {
    field.error = true
    error = true
    field.helperText = 'Campo requerido'
  } else {
    field.error = false
    field.helperText = ''
  }

  return [field, error]
}

const validEntityState = (entityState: IEntityState, setEntityState: (value: IEntityState) => void) => {
  const [businessName, errorBusinessName] = checkCreateEntity(entityState.businessName)
  const [shortName, errorShortName] = checkCreateEntity(entityState.shortName)
  const [documentNumber, errorDocumentNumber] = checkCreateEntity(entityState.documentNumber)
  const [documentTypeId, errorDocumentTypeId] = checkCreateEntity(entityState.documentTypeId)
  const [economicActivityId, errorEconomicActivityId] = checkCreateEntity(entityState.economicActivityId)
  const [natureId, errorNatureId] = checkCreateEntity(entityState.natureId)
  const [regimenId, errorRegimenId] = checkCreateEntity(entityState.regimenId)

  setEntityState({
    businessName,
    shortName,
    documentNumber,
    documentTypeId,
    economicActivityId,
    natureId,
    regimenId
  })

  const errors =
    errorBusinessName ||
    errorShortName ||
    errorDocumentNumber ||
    errorDocumentTypeId ||
    errorEconomicActivityId ||
    errorNatureId ||
    errorRegimenId

  return !errors
}

const validUserState = (userState: IUserState, setUserState: (value: IUserState) => void) => {
  const [firstName, errorFirstName] = checkCreateEntity(userState.firstName)
  const [secondName, errorSecondName] = checkCreateEntity(userState.secondName)
  const [lastName, errorLastName] = checkCreateEntity(userState.lastName)
  const [secondLastName, errorSecondLastName] = checkCreateEntity(userState.secondLastName)
  const [email, errorEmail] = checkCreateEntity(userState.email)

  setUserState({
    firstName,
    secondName,
    lastName,
    secondLastName,
    email
  })

  const errors = errorFirstName || errorSecondName || errorLastName || errorSecondLastName || errorEmail

  return !errors
}

const validBranchOfficeState = (
  branchOfficeState: IBranchOfficeState,
  setBranchOfficeState: (value: IBranchOfficeState) => void
) => {
  const [name, errorName] = checkCreateEntity(branchOfficeState.name)
  const [address, errorAddress] = checkCreateEntity(branchOfficeState.address)
  const [telephone, errorTelephone] = checkCreateEntity(branchOfficeState.telephone)
  const [city, errorCity] = checkCreateEntity(branchOfficeState.city)

  setBranchOfficeState({
    name,
    address,
    telephone,
    city
  })

  const errors = errorName || errorAddress || errorTelephone || errorCity

  return !errors
}

const getSteps = () => {
  return ['Datos Usuario', 'Datos Entidad', 'Datos Sede', 'Confirmar y Crear']
}

export interface CreateEntityProps {}

const CreateEntity: React.FC<CreateEntityProps> = () => {
  const classes = useStyles()
  const steps = getSteps()
  const { environmentKey } = useParams<{ environmentKey: string }>()
  const [activeStep, setActiveStep] = React.useState(0)
  const [userState, setUserState] = React.useState(defaultUserState)
  const [entityState, setEntityState] = React.useState(defaultEntityState)
  const [branchOfficeState, setBranchOfficeState] = React.useState(defaultBranchOfficeState)
  const { createEntity, success, isLoading, result } = useEntity(environmentKey ?? '')
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleNext = () => {
    let validState = false

    switch (activeStep) {
      case 0:
        validState = validUserState(userState, setUserState)
        break
      case 1:
        validState = validEntityState(entityState, setEntityState)
        break
      case 2:
        validState = validBranchOfficeState(branchOfficeState, setBranchOfficeState)
        break
    }

    if (validState) {
      setActiveStep(activeStep + 1)
    }
  }

  const handleClose = () => {
    setOpen(false)
    navigate(`/${environmentKey ?? ''}/licenses`)
  }

  React.useEffect(() => {
    if (success) {
      setOpen(true)
    }
  }, [success])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSave = () => {
    createEntity({ userState, entityState, branchOfficeState })
  }

  const stepContent = [
    <StepDataUser key='1' {...{ userState, setUserState }} />,
    <StepDataEntity key='2' {...{ entityState, setEntityState }} />,
    <StepDataBranchOffice key='3' {...{ environmentKey, branchOfficeState, setBranchOfficeState }} />,
    <StepCreateAndCommit key='4' {...{ userState, entityState, branchOfficeState }} />
  ]

  return (
    <Container maxWidth='md' className={classes.container}>
      <Paper elevation={3}>
        <Backdrop className={classes.backdrop} open={isLoading} data-testid={'test-backdrop'}>
          <CircularProgress color='inherit' size={100} />
        </Backdrop>

        <Dialog open={open} onClose={handleClose} className={classes.backdrop}>
          <DialogTitle>Nueva entidad</DialogTitle>
          <DialogContent>
            <DialogContentText>
              La entidad {result?.name ?? ''} se ha creado correctamente. Recuerde que puede ingresar con su correo
              electrónico: {userState.email.value}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Aceptar</Button>
          </DialogActions>
        </Dialog>

        <Typography variant='h4' className={classes.title}>
          Crear Nueva Entidad
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean; active?: boolean } = {}

            stepProps.active = index === activeStep
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box data-testid='create-entity-title' textAlign='left' fontWeight='fontWeightBold' className={classes.box}>
          {steps[activeStep]}
        </Box>
        {stepContent[activeStep]}
        <Grid
          item
          xs={12}
          container
          direction='row'
          justifyContent='space-around'
          alignItems='center'
          className={classes.buttonContent}
        >
          <Grid item xs={6}>
            {activeStep !== 0 && (
              <Fab variant='extended' size='medium' color='primary' onClick={handleBack} data-testid='btn-back-step'>
                <ArrowBackIcon />
                <Typography className={classes.buttonText}>atras</Typography>
              </Fab>
            )}
          </Grid>

          <Grid item xs={6}>
            {activeStep === steps.length - 1 && (
              <Fab variant='extended' size='medium' color='primary' onClick={handleSave} data-testid='btn-save'>
                <Typography className={classes.buttonText}>guardar</Typography>
                <SaveIcon />
              </Fab>
            )}
            {activeStep < steps.length - 1 && (
              <Fab variant='extended' size='medium' color='primary' onClick={handleNext} data-testid='btn-next-step'>
                <Typography className={classes.buttonText}>siguiente</Typography>
                <ArrowForwardIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default CreateEntity
