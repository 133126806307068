import { Divider, Grid, Typography, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { CreateEntityValue, ICreateEntityState } from '../../models/CreateEntityModel'
import EntityTypesModel from '../../models/EntityTypesModel'
import { DocumentTypes, EconomicActivityTypes, NatureTypes, RegimenTypes } from './EntityTypes'

export interface StepCreateAndCommitProps extends ICreateEntityState {}

const useStyles = makeStyles((theme: Theme) => ({
  informationValue: {
    width: '90%',
    display: 'inline-flex',
    textAlign: 'left'
  },
  newRow: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important'
    }
  }
}))

const StepCreateAndCommit: React.FC<StepCreateAndCommitProps> = ({ userState, entityState, branchOfficeState }) => {
  const classes = useStyles()

  const getFullName = () => {
    let fullName = userState.firstName.value.toString()
    if (userState.secondName.value !== '') {
      fullName += ` ${userState.secondName.value}`
    }

    fullName += ` ${userState.lastName.value}`
    if (userState.secondLastName.value !== '') {
      fullName += ` ${userState.secondLastName.value}`
    }

    return fullName
  }

  const getEntityTypeValue = (entityTypes: EntityTypesModel[], field: CreateEntityValue) => {
    return entityTypes.find(entityType => entityType.id === field.value)?.value ?? ''
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container direction='row' justifyContent='space-around' alignItems='left'>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Nombres
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-user-full-name'>
            {getFullName()}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Correo
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-user-email'>
            {userState.email.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='left'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Razón social
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-business-name'>
            {entityState.businessName.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Documento
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-document'>
            {`${getEntityTypeValue(DocumentTypes, entityState.documentTypeId)} - ${entityState.documentNumber.value}`}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='left'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Nombre corto de la entidad
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-short-name'>
            {entityState.shortName.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Actividad económica
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-economic-activity'>
            {getEntityTypeValue(EconomicActivityTypes, entityState.economicActivityId)}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='left'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Tipo naturaleza
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-nature'>
            {getEntityTypeValue(NatureTypes, entityState.natureId)}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Tipo regimen
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-entity-regimen'>
            {getEntityTypeValue(RegimenTypes, entityState.regimenId)}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='left'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Nombre sede
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-office-name'>
            {branchOfficeState.name.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Lugar
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-office-city'>
            {branchOfficeState.city.value?.name}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='left'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Dirección sede
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-office-address'>
            {branchOfficeState.address.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.informationValue} variant='h6'>
            Teléfono sede
          </Typography>
          <Typography className={classes.informationValue} data-testid='data-create-office-telephone'>
            {branchOfficeState.telephone.value}
          </Typography>
          <Divider className={classes.informationValue} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepCreateAndCommit
