import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useNotificationContext } from '../../ContextProviders/NotificationProvider'
interface HeadLicensesProps {
  environmentKey: string
  viewLicensesActives: boolean
  setViewLicensesActives: (value: boolean) => void
}

const HeadLicenses: React.FC<HeadLicensesProps> = ({ environmentKey, viewLicensesActives, setViewLicensesActives }) => {
  const navigate = useNavigate()
  const { notification } = useNotificationContext()

  const goCreateEntity = () => {
    navigate(`/${environmentKey}/create`)
  }

  const isVisibleCreateEntity = () => {
    return notification?.level !== 'error'
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewLicensesActives((event.target as HTMLInputElement).value === 'true')
  }

  return (
    <>
      <Grid container direction='row' justifyContent='space-between' alignContent='center'>
        <Grid item>
          <h3 data-testid='licenses-header'>Ambiente: {environmentKey} - Licencias</h3>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={viewLicensesActives}
              onChange={handleChange}
            >
              <FormControlLabel value='true' control={<Radio />} label='Activas' />
              <FormControlLabel value='false' control={<Radio />} label='Inactivas' />
            </RadioGroup>
          </FormControl>
          {isVisibleCreateEntity() && (
            <Button
              onClick={goCreateEntity}
              data-testid={'btn-create-entity'}
              variant='contained'
              size='small'
              color='primary'
              sx={{ mx: 3 }}
            >
              Crear
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  )
}
export default HeadLicenses
