import React from 'react'
import fetchApi from '../api/fetchApi'
import EnvironmentModel from '../models/EnvironmentModel'
import { useNotificationContext } from './NotificationProvider'

interface EnvironmentProviderValueModel {
  environments: EnvironmentModel[]
  getEnvironments: () => void
  isLoading: boolean
}

interface EnvironmentsProviderProps {
  children: React.ReactNode
}

const EnvironmentsContext = React.createContext<EnvironmentProviderValueModel>({
  environments: [],
  getEnvironments: () => {
    /* do nothing */
  },
  isLoading: false
})

export const useEnvironmentsContext = () => React.useContext(EnvironmentsContext)

export const EnvironmentsProvider: React.SFC<EnvironmentsProviderProps> = ({ children }) => {
  const [environments, setEnvironments] = React.useState([] as EnvironmentModel[])
  const [isLoading, setIsLoading] = React.useState(false)
  const { setNotification } = useNotificationContext()

  const setEnvironmentsError = (message = 'Ha ocurrido un error, intente recarga la página') => {
    setNotification({ message, level: 'error' })
  }

  const setEnvironmentsInfo = (count: number) => {
    setNotification({ message: 'Ambientes de iMedicalCloud encontrados ' + count.toString() + '.', level: 'info' })
  }

  const getEnvironments = () => {
    fetchApi('api/v1/environments')
      .then(
        (res: EnvironmentModel[]) => {
          setEnvironments(res)
          setEnvironmentsInfo(res.length)
        },
        () => {
          setEnvironmentsError('Ha ocurrido un error al obtener los ambientes.')
        }
      )
      .then(
        () => {
          setIsLoading(true)
        },
        () => {
          /* do nothing */
        }
      )
  }

  return (
    <EnvironmentsContext.Provider value={{ environments, getEnvironments, isLoading }}>
      {children}
    </EnvironmentsContext.Provider>
  )
}
