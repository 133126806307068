import { Button, Container, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import authProvider from '../aad/AuthProvider'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    top: '10vh',
    padding: theme.spacing(3),
    background: 'rgba(255, 255, 255, 0.8);',
    textAlign: 'center'
  },
  logo: {
    width: '60%'
  },
  title: {
    fontSize: '2.5em'
  },
  button: {
    marginTop: theme.spacing(4),
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

export interface WelcomeProps {}

const Welcome: React.SFC<WelcomeProps> = () => {
  const classes = useStyles()

  return (
    <Container maxWidth='md' className={classes.container}>
      <div>
        <img src='./images/logoimedical.png' className={classes.logo} alt='logo' />
      </div>

      <h1 data-testid='welcome-message' className={classes.title}>
        Application Admin
      </h1>
      <Button
        className={classes.button}
        variant='contained'
        size='large'
        color='primary'
        onClick={() => authProvider.login()}
      >
        Entrar
      </Button>
    </Container>
  )
}

export default Welcome
