import { AuthError } from 'msal'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import AzureAD, { AuthenticationState, IAccountInfo } from 'react-aad-msal'
import authProvider from './aad/AuthProvider'
import Main from './Pages/Main'
import Welcome from './Pages/Welcome'

interface AzureFunctionProps {
  login: () => void
  logout: () => void
  authenticationState: AuthenticationState
  error: AuthError
  accountInfo: IAccountInfo | null
}

const theme = createTheme()

const App: React.SFC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <AzureAD provider={authProvider} forceLogin={false}>
          {({ authenticationState }: AzureFunctionProps) => {
            if (authenticationState === AuthenticationState.Authenticated) {
              return <Main />
            } else {
              return <Welcome />
            }
          }}
        </AzureAD>
      </div>
    </ThemeProvider>
  )
}

export default App
