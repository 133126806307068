import { Button, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { useLocation } from 'react-router-dom'
import { useExcelTemplateContext } from '../../ContextProviders/ExcelTemplateProvider'
import BackDropLoad from '../backDropLoadComponents/BackDropLoad'
import { IStateRouter } from '../companyPanelComponents/CompanyPanel'
import TextFieldTemplateList from './TextFieldTemplateList'

const ExcelTemplateDownload: React.FC = () => {
  const [excelTemplateId, setExcelTemplateId] = React.useState(0)
  const { isLoading, downLoadExcelTemplate } = useExcelTemplateContext()
  const state = useLocation().state as IStateRouter
  const [downloadEnabled, setDownloadEnabled] = React.useState(false)

  const handlerClickButtonDownloadExcelTemplate = () => {
    downLoadExcelTemplate(state.environmentName || '', state.company, excelTemplateId)
  }

  useEffect(() => {
    if (excelTemplateId !== 0) {
      setDownloadEnabled(true)
    }
  }, [excelTemplateId])

  return (
    <>
      <BackDropLoad open={isLoading} />
      <Grid
        data-testid={'test-excel-template-download'}
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
        sx={{ p: 1, m: 2 }}
      >
        <Grid item xs={6} sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <TextFieldTemplateList {...{ excelTemplateId, setExcelTemplateId }} />
        </Grid>
        <Grid item xs={2}>
          <Button
            startIcon={<DownloadIcon />}
            variant='contained'
            onClick={() => {
              handlerClickButtonDownloadExcelTemplate()
            }}
            disabled={!downloadEnabled}
            data-testid={'test-button-excel-template-download'}
          >
            Descargar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ExcelTemplateDownload
