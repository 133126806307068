import { MenuItem, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useExcelTemplateContext } from '../../ContextProviders/ExcelTemplateProvider'

export interface ITextFieldTemplateList {
  excelTemplateId: number
  setExcelTemplateId: (value: number) => void
}
const TextFieldTemplateList: React.FC<ITextFieldTemplateList> = ({ excelTemplateId, setExcelTemplateId }) => {
  const { excelTemplates, getExcelTemplates } = useExcelTemplateContext()

  useEffect(() => {
    getExcelTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextField
      data-testid='list-template'
      placeholder='seleccionar plantilla'
      size='small'
      select
      variant='outlined'
      required
      margin='normal'
      fullWidth
      id='plantilla'
      label='plantilla'
      name='plantilla'
      autoComplete='off'
      autoFocus
      value={excelTemplateId !== 0 ? excelTemplateId : ''}
      onChange={e => setExcelTemplateId((e.target.value as unknown) as number)}
    >
      {excelTemplates
        .filter(templateItem => templateItem.status && !templateItem.has_additional_parameters)
        .map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default TextFieldTemplateList
