import { Grid, TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { IUserState } from '../../models/CreateEntityModel'

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '90%'
  },
  newRow: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important'
    }
  }
}))

export interface StepDataUserProps {
  userState: IUserState
  setUserState: (values: IUserState) => void
}

const StepDataUser: React.FC<StepDataUserProps> = ({ userState, setUserState }) => {
  const classes = useStyles()

  const setValue = (field: keyof IUserState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserState({ ...userState, [field]: { ...userState[field], value: event.target.value } })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container direction='row' justifyContent='space-around' alignItems='center'>
        <Grid item xs={12} sm={6}>
          <TextField
            id='first-name'
            data-testid='data-user-first-name'
            label='Primer Nombre'
            variant='outlined'
            margin='dense'
            onChange={setValue('firstName')}
            className={classes.textField}
            {...userState.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='second-name'
            data-testid='data-user-second-name'
            label='Segundo Nombre'
            variant='outlined'
            margin='dense'
            onChange={setValue('secondName')}
            className={classes.textField}
            {...userState.secondName}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id='last-name'
            data-testid='data-user-last-name'
            label='Primer Apellido'
            variant='outlined'
            margin='dense'
            onChange={setValue('lastName')}
            className={classes.textField}
            {...userState.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='second-last-name'
            data-testid='data-user-second-last-name'
            label='Segundo Apellido'
            variant='outlined'
            margin='dense'
            onChange={setValue('secondLastName')}
            className={classes.textField}
            {...userState.secondLastName}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container className={classes.newRow}>
        <Grid item xs={12} sm={6}>
          <TextField
            id='email'
            data-testid='data-user-email'
            label='Correo'
            variant='outlined'
            margin='dense'
            onChange={setValue('email')}
            className={classes.textField}
            {...userState.email}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default StepDataUser
