import React from 'react'
import { Grid, TextField, MenuItem, SelectProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EntityTypesModel from '../../models/EntityTypesModel'
import { IEntityState } from '../../models/CreateEntityModel'
import { DocumentTypes, EconomicActivityTypes, NatureTypes, RegimenTypes } from './EntityTypes'

export interface StepDataEntityProps {
  entityState: IEntityState
  setEntityState(value: IEntityState): void
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '90%'
  },
  businessNameField: {
    [theme.breakpoints.down('sm')]: {
      width: '90% !important'
    },
    width: '95%'
  },
  newRow: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important'
    }
  }
}))

const StepDataEntity: React.FC<StepDataEntityProps> = ({ entityState, setEntityState }) => {
  const classes = useStyles()

  const setValue = (field: keyof IEntityState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntityState({ ...entityState, [field]: { ...entityState[field], value: event.target.value } })
  }

  const addSelectItems = (items: EntityTypesModel[]) => {
    return items.map(item => (
      <MenuItem key={`data-entity-select-${item.id}`} value={item.id}>
        {item.value}
      </MenuItem>
    ))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='business-name'
          data-testid='data-entity-business-name'
          label='Razón social'
          variant='outlined'
          margin='dense'
          className={classes.businessNameField}
          onChange={setValue('businessName')}
          {...entityState.businessName}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id='short-name'
            data-testid='data-entity-short-name'
            label='Nombre corto'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('shortName')}
            {...entityState.shortName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='economic-activity'
            select
            SelectProps={
              {
                SelectDisplayProps: { 'data-testid': 'data-entity-economic-activity-btn' }
              } as SelectProps
            }
            data-testid='data-entity-economic-activity'
            variant='outlined'
            margin='dense'
            label='Tipo de actividad económica'
            className={classes.textField}
            onChange={setValue('economicActivityId')}
            {...entityState.economicActivityId}
          >
            {addSelectItems(EconomicActivityTypes)}
          </TextField>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id='document-type'
            select
            SelectProps={
              {
                SelectDisplayProps: { 'data-testid': 'data-entity-document-type-btn' }
              } as SelectProps
            }
            data-testid='data-entity-document-type'
            label='Tipo documento'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('documentTypeId')}
            {...entityState.documentTypeId}
          >
            {addSelectItems(DocumentTypes)}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='document-number'
            data-testid='data-entity-document-number'
            label='Numero de documento'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('documentNumber')}
            {...entityState.documentNumber}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id='nature'
            select
            SelectProps={
              {
                SelectDisplayProps: { 'data-testid': 'data-entity-nature-btn' }
              } as SelectProps
            }
            data-testid='data-entity-nature'
            label='Tipo naturaleza'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('natureId')}
            {...entityState.natureId}
          >
            {addSelectItems(NatureTypes)}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='regimen'
            select
            SelectProps={
              {
                SelectDisplayProps: { 'data-testid': 'data-entity-regimen-btn' }
              } as SelectProps
            }
            data-testid='data-entity-regimen'
            variant='outlined'
            margin='dense'
            label='Tipo regimen'
            className={classes.textField}
            onChange={setValue('regimenId')}
            {...entityState.regimenId}
          >
            {addSelectItems(RegimenTypes)}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepDataEntity
