import React, { useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import { useLocation } from 'react-router-dom'
import { DropzoneArea } from 'react-mui-dropzone'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useExcelTemplateContext } from '../../ContextProviders/ExcelTemplateProvider'
import BackDropLoad from '../backDropLoadComponents/BackDropLoad'
import { IStateRouter } from '../companyPanelComponents/CompanyPanel'
import TextFieldTemplateList from './TextFieldTemplateList'
import ViewInconsistencies from './ViewInconsistencies'

const MaxSizeTemplates = 12582912

const ExcelTemplateProcess: React.FC = () => {
  const [excelTemplateId, setExcelTemplateId] = React.useState(0)
  const [file, setFile] = React.useState<File | null>(null)
  const { isLoading, uploadExcelTemplate, inconsistencies, setInconsistencies } = useExcelTemplateContext()
  const [processDisabled, setProcessDisabled] = React.useState(true)
  const state = useLocation().state as IStateRouter

  const handlerClickButtonProcessExcelTemplate = () => {
    if (file !== null) {
      uploadExcelTemplate(state.environmentName || '', state.company, excelTemplateId, file)
    }
  }

  const handleOnLoad = (newFile: File) => {
    if (newFile !== null && newFile !== undefined) {
      setFile(newFile)
    }
  }

  useEffect(() => {
    setProcessDisabled(excelTemplateId === 0 || file === null)
  }, [excelTemplateId, file])

  const clearData = () => {
    setFile(null)
    setExcelTemplateId(0)
    setInconsistencies([])
  }

  return (
    <>
      <BackDropLoad open={isLoading} />
      {inconsistencies && inconsistencies?.length === 0 && (
        <Grid
          data-testid={'test-excel-template-process'}
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          sx={{ p: 1, m: 2 }}
        >
          <Grid item xs={6} sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <TextFieldTemplateList {...{ excelTemplateId, setExcelTemplateId }} />
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<UploadIcon />}
              variant='contained'
              onClick={() => {
                handlerClickButtonProcessExcelTemplate()
              }}
              disabled={processDisabled}
              data-testid={'test-button-excel-template-download'}
            >
              Procesar
            </Button>
          </Grid>
          <Grid data-testid='dropzone-area' item xs={12} sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <DropzoneArea
              acceptedFiles={['application/x-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12']}
              dropzoneText={'Arrastra y suelta la plantilla excel aquí o haz clic'}
              onChange={files => handleOnLoad(files[0])}
              filesLimit={1}
              maxFileSize={MaxSizeTemplates}
              onDelete={() => setFile(null)}
              showFileNames={true}
              getPreviewIcon={() => {
                return <InsertDriveFileIcon color='success' fontSize='large' />
              }}
            />
          </Grid>
        </Grid>
      )}
      {inconsistencies && inconsistencies?.length > 0 && (
        <Grid>
          <ViewInconsistencies fileName={file?.name ?? ''} {...{ inconsistencies, clearData }} />
        </Grid>
      )}
    </>
  )
}

export default ExcelTemplateProcess
