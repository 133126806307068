import React from 'react'
import fetchApi from '../api/fetchApi'
import CityModel from '../models/CityModel'

export type citiesParams = {
  filter: string
}

export const useCities = (enviromentkey: string) => {
  const [cities, setCities] = React.useState([] as CityModel[])
  const [isLoading, setIsLoading] = React.useState(false)

  const getCities = (filterCities: citiesParams) => {
    setCities([])
    setIsLoading(true)

    const params = new URLSearchParams(filterCities)
    fetchApi(`api/v1/environments/${enviromentkey}/cities?${params.toString()}`).then(
      (res: CityModel[]) => {
        setCities([...res])
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
      }
    )
  }

  return { cities, getCities, isLoading }
}
