import { Box } from '@mui/material'
import React from 'react'

interface ITabPanelProps {
  children: React.ReactNode
  index: number
  value: number
}
const TabPanel: React.FC<ITabPanelProps> = props => {
  const { children, index, value, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default TabPanel
