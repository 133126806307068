import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, Drawer, FormControlLabel, Grid, Switch, TextField, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import 'date-fns'
import React from 'react'
import LicensesModel from '../models/LicensesModel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(3)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300
    },
    btn_cancel: {
      textAlign: 'right'
    },
    btn_edit: {
      textAlign: 'left'
    }
  })
)

const detectLocaleDateString = () => {
  const now = new Date('2020-1-23')
  let str = now.toLocaleDateString()
  str = str.replace('23', 'dd')
  str = str.replace('1', 'MM')
  str = str.replace('2020', 'yyyy')
  return str
}

export interface UpdateLicenseProps {
  license: LicensesModel
  setLicense: (license: LicensesModel) => void
  updateLicense: (license: LicensesModel) => void
  isOpen: boolean
  togglePanel: () => void
}

const UpdateLicense: React.FC<UpdateLicenseProps> = ({ isOpen, togglePanel, license, setLicense, updateLicense }) => {
  const classes = useStyles()

  const handleSaveDateChange = (date: Date | null) => {
    setLicense({ ...license, save_expiration_date: date })
  }

  const handlePrintDateChange = (date: Date | null) => {
    setLicense({ ...license, print_expiration_date: date })
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicense({ ...license, is_active: event.target.checked })
  }

  return (
    <Drawer anchor='right' open={isOpen} onClose={togglePanel}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={classes.container} data-testid={'edit-panel-' + license.entity_id}>
          <h3>Editar licencia</h3>
          <div className={classes.container}>
            <DatePicker
              inputFormat={detectLocaleDateString()}
              label='Lic para Guardar'
              value={license.save_expiration_date}
              onChange={handleSaveDateChange}
              renderInput={params => <TextField id='save-date-picker' variant='standard' margin='normal' {...params} />}
            />
          </div>
          <div className={classes.container}>
            <DatePicker
              inputFormat={detectLocaleDateString()}
              label='Lic para Imprimir'
              value={license.print_expiration_date}
              onChange={handlePrintDateChange}
              renderInput={params => (
                <TextField id='print-date-picker' variant='standard' margin='normal' {...params} />
              )}
            />
          </div>
          <div className={classes.container}>
            <FormControlLabel
              control={
                <Switch
                  checked={license.is_active}
                  onChange={handleActiveChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={license.is_active ? 'Activa' : 'Inactiva'}
            />
          </div>
          <br />
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={6} className={classes.btn_edit}>
                <Button
                  data-testid='btn-edit'
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    updateLicense(license)
                    togglePanel()
                  }}
                  disabled={!(license.save_expiration_date && license.print_expiration_date)}
                >
                  Editar
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.btn_cancel}>
                <Button data-testid='btn-cancel' variant='contained' color='inherit' onClick={togglePanel}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </LocalizationProvider>
    </Drawer>
  )
}

export default UpdateLicense
