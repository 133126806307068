import React from 'react'
import { makeStyles } from '@mui/styles'
import { Autocomplete, CircularProgress, Grid, TextField, Theme } from '@mui/material'
import clsx from 'clsx'
import { IBranchOfficeState } from '../../models/CreateEntityModel'
import { citiesParams, useCities } from '../../hooks/useCities'
import CityModel from '../../models/CityModel'

export interface StepDataBranchOfficeProps {
  branchOfficeState: IBranchOfficeState
  setBranchOfficeState(value: IBranchOfficeState): void
  environmentKey?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '90%'
  },
  autoCompleteTextField: {
    display: 'inline-flex'
  },
  newRow: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important'
    }
  }
}))

const StepDataBranchOffice: React.FC<StepDataBranchOfficeProps> = ({
  branchOfficeState,
  setBranchOfficeState,
  environmentKey
}) => {
  const searchDelayInMs = 1000
  const classes = useStyles()
  const { cities, getCities } = useCities(environmentKey ?? '')
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const loading = isLoading && open
  const { value: cityValue, ...restCity } = branchOfficeState.city

  const setValue = (field: keyof IBranchOfficeState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setBranchOfficeState({ ...branchOfficeState, [field]: { ...branchOfficeState[field], value: event.target.value } })
  }

  const setCity = (_event: React.SyntheticEvent, value: CityModel | null) => {
    setBranchOfficeState({
      ...branchOfficeState,
      city: { ...branchOfficeState.city, value }
    })
  }

  const searchCity = (_event: React.SyntheticEvent, value: string) => {
    setSearch(value)
  }

  React.useEffect(() => {
    setIsLoading(true)
    const searchTimer = setTimeout(() => {
      const filterValue: citiesParams = {
        filter: search.trim()
      }
      getCities(filterValue)
      setIsLoading(false)
    }, searchDelayInMs)

    return () => {
      clearTimeout(searchTimer)
    }
  }, [search])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container direction='row' justifyContent='space-around' alignItems='center'>
        <Grid item xs={12} sm={6}>
          <TextField
            id='branch-office-name'
            data-testid='data-branch-office-name'
            label='Nombre sede'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('name')}
            {...branchOfficeState.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id='branch-office-city'
            data-testid='data-branch-office-city'
            options={cities}
            getOptionLabel={option => `${option.name} - ${option.state_name} (${option.country_code})`}
            loading={loading}
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            noOptionsText='No hay opciones'
            loadingText='Cargando...'
            clearText='Borrar'
            openText='Abrir'
            closeText='Cerrar'
            onInputChange={searchCity}
            className={clsx(classes.textField, classes.autoCompleteTextField)}
            value={cityValue}
            onChange={setCity}
            renderInput={params => (
              <TextField
                {...params}
                {...restCity}
                label='Lugar'
                variant='outlined'
                margin='dense'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        className={classes.newRow}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id='branch-office-address'
            data-testid='data-branch-office-address'
            label='Dirección sede'
            variant='outlined'
            margin='dense'
            className={classes.textField}
            onChange={setValue('address')}
            {...branchOfficeState.address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='branch-office-telephone'
            data-testid='data-branch-office-telephone'
            label='Teléfono sede'
            variant='outlined'
            margin='dense'
            type='tel'
            className={classes.textField}
            onChange={setValue('telephone')}
            {...branchOfficeState.telephone}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepDataBranchOffice
