import { Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material'
import React from 'react'
import KeyIcon from '@mui/icons-material/Key'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useLocation, useNavigate } from 'react-router-dom'
import LicensesModel from '../../models/LicensesModel'
import { IStateRouter } from '../companyPanelComponents/CompanyPanel'

interface ICompanyServicesConfiguratorProps {
  updateLicensesPanel: () => void
  company: LicensesModel
}
const CompanyServicesConfigurator: React.FC<ICompanyServicesConfiguratorProps> = ({ company, updateLicensesPanel }) => {
  const navigate = useNavigate()
  const state = useLocation().state as IStateRouter

  const handleOnClickGoBulkData = () => {
    navigate(`bulk/data`, { state: { ...state } })
  }

  return (
    <>
      <Paper
        elevation={12}
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, width: '100%' }}
        data-testid='component-company-services'
      >
        <Typography component='h3' variant='h5' align='center'>
          {`Servicios`}
        </Typography>
        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={0}>
          <Grid item xs={12}>
            <List sx={{ width: '100%' }}>
              <ListItem
                onClick={() => {
                  updateLicensesPanel()
                }}
                data-testid='test-license'
              >
                <ListItemAvatar>
                  <KeyIcon color={'primary'} />
                </ListItemAvatar>
                <ListItemText
                  primary='Licencias'
                  secondary={'Modulo para la actualización de licencias de los clientes de iMedical Services'}
                />
                <ListItemText primary='Renovar' secondary={company.save_expiration_date?.toLocaleString() ?? ''} />
                <ListItemText primary='Vencimiento' secondary={company.print_expiration_date?.toLocaleString() ?? ''} />
              </ListItem>
              <Divider variant='inset' component='li' />
              <ListItem
                onClick={() => {
                  handleOnClickGoBulkData()
                }}
                data-testid='button-go-bulk-data'
              >
                <ListItemAvatar>
                  <FileUploadIcon color={'primary'} />
                </ListItemAvatar>
                <ListItemText
                  primary='Cargas Masivas'
                  secondary={
                    'Modulo para la descarga y carga de plantillas que permiten cargas masivas de diferentes datos (Pacientes,CUPs, ...)'
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CompanyServicesConfigurator
