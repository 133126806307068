/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useState } from 'react'
import fetchApi, { fetchApiCustom, fetchApiProcessTemplate } from '../api/fetchApi'
import ApiErrorModel, { ApiErrorIncosistency } from '../models/ApiErrorModel'
import LicensesModel from '../models/LicensesModel'
import { IExcelTemplate } from '../models/ResponseExcelTeamplate'
import AuthProvider from '../aad/AuthProvider'
import InconsistencyModel from '../models/InconsistencyModel'
import { useNotificationContext } from './NotificationProvider'

interface ExcelTemplateProviderModel {
  excelTemplates: IExcelTemplate[]
  getExcelTemplates: () => void
  downLoadExcelTemplate: (environmentKey: string, company: LicensesModel, excelTemplateId: number) => void
  uploadExcelTemplate: (environmentKey: string, company: LicensesModel, excelTemplateId: number, template: File) => void
  isLoading: boolean
  isUpload: boolean
  inconsistencies: InconsistencyModel[]
  setInconsistencies: (value: InconsistencyModel[]) => void
}

const ExcelTemplateContext = createContext<ExcelTemplateProviderModel>({
  excelTemplates: [],
  getExcelTemplates: () => {
    return
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  downLoadExcelTemplate: (_environmentKey: string, _company: LicensesModel, _excelTemplateId: number) => {
    return
  },

  uploadExcelTemplate: (
    _environmentKey: string,
    _company: LicensesModel,
    _excelTemplateId: number,
    _template: File
  ) => {
    return
  },
  isLoading: false,
  isUpload: false,
  inconsistencies: [],
  setInconsistencies: (value: InconsistencyModel[]) => {
    return
  }
})

export const useExcelTemplateContext = () => useContext(ExcelTemplateContext)

export interface IExcelTermplateContextProps {
  children: React.ReactNode
}

export const ExcelTemplateProvider: React.FC<IExcelTermplateContextProps> = ({ children }) => {
  const [excelTemplates, setExcelTemplates] = useState<IExcelTemplate[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const { setNotification } = useNotificationContext()
  const [inconsistencies, setInconsistencies] = useState<InconsistencyModel[]>([])

  const setExcelTemplateError = (message: string) => {
    setNotification({ message, level: 'error' })
  }

  const setExcelTemplateInfo = (message: string) => {
    setNotification({ message, level: 'info' })
  }

  const getExcelTemplates = () => {
    setIsLoading(true)
    fetchApi('api/v1/ExcelTemplate')
      .then(
        (res: IExcelTemplate[]) => {
          setExcelTemplates(res)
        },
        (error: ApiErrorModel) => {
          setExcelTemplateError(error.error_message || 'Ha ocurrido un error al obtener las plantillas excel.')
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  const downLoadExcelTemplate = (environmentKey: string, company: LicensesModel, excelTemplateId: number) => {
    setIsLoading(true)
    fetchApiCustom(
      `api/v1/ExcelTemplate/download?bussinesGroupId=${company.business_group_id}&companyId=${company.entity_id}&excelTemplateId=${excelTemplateId}&enviromentName=${environmentKey}`
    )
      .then(
        async response => {
          const header = response.headers.get('Content-Disposition')
          const parts = header?.split(';') ?? ''
          const filename = parts[1].split('=')[1]
          const blob = await response.blob()
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        },
        (error: ApiErrorModel) => {
          setExcelTemplateError(
            error.error_message || 'Ha ocurrido un error al descargar la plantillas excel seleccionada.'
          )
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  const uploadExcelTemplate = async (
    environmentKey: string,
    company: LicensesModel,
    excelTemplateId: number,
    template: File
  ) => {
    setIsLoading(true)
    const accessTokenResponse = await AuthProvider.getAccessToken()
    const formData = new FormData()
    formData.append('excelTemplate', template ?? '', template.name)

    fetchApiProcessTemplate(
      `api/v1/ExcelTemplate/process?bussinesGroupId=${company.business_group_id}&companyId=${company.entity_id}&excelTemplateId=${excelTemplateId}&enviromentName=${environmentKey}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + accessTokenResponse.accessToken
        },
        body: formData
      }
    )
      .then(
        response => {
          setIsUpload(response.ok)
          setExcelTemplateInfo(`Se ha procesado correctamente la plantilla ${template?.name}`)
        },
        (error: ApiErrorIncosistency) => {
          if (error.error_code === 406) {
            setExcelTemplateError(`se han encontrado inconsistencias en la plantilla excel seleccionada.`)
            setInconsistencies(error.inconsitencies)
          } else {
            setExcelTemplateError(
              error.error_message || 'Ha ocurrido un error al cargar la plantilla excel seleccionada.'
            )
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ExcelTemplateContext.Provider
      value={{
        excelTemplates,
        getExcelTemplates,
        isLoading,
        downLoadExcelTemplate,
        uploadExcelTemplate,
        isUpload,
        inconsistencies,
        setInconsistencies
      }}
    >
      {children}
    </ExcelTemplateContext.Provider>
  )
}
