import EntityTypesModel from '../../models/EntityTypesModel'

export const DocumentTypes: EntityTypesModel[] = [
  { id: 1, value: 'CC - Cédula' },
  { id: 2, value: 'NIT - NIT' },
  { id: 9, value: 'CE - Cédula de extranjeria' }
]

export const EconomicActivityTypes: EntityTypesModel[] = [
  { id: 6065, value: '8610 Actividades de hospitales y clínicas, con internación' },
  { id: 6066, value: '8621 Actividades de la práctica médica, sin internación' },
  { id: 6067, value: '8622 Actividades de la práctica odontológica' },
  { id: 6068, value: '8691 Actividades de apoyo diagnóstico' },
  { id: 6069, value: '8692 Actividades de apoyo terapéutico' },
  { id: 6070, value: '8699 Otras actividades de atención de la salud humana' },
  { id: 6071, value: '8710 Actividades de atención residencial medicalizada de tipo general' },
  {
    id: 6072,
    value:
      '8720 Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas'
  },
  {
    id: 6073,
    value: '8730 Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas'
  },
  { id: 6074, value: '8790 Otras actividades de atención en instituciones con alojamiento' }
]

export const NatureTypes: EntityTypesModel[] = [
  { id: 2683, value: 'Persona juridica' },
  { id: 2684, value: 'Persona natural' }
]

export const RegimenTypes: EntityTypesModel[] = [
  { id: 2685, value: 'Simplificado' },
  { id: 2686, value: 'Común' },
  { id: 2687, value: 'Unico Impositivo' },
  { id: 2688, value: 'Gran Contribuyente' },
  { id: 2689, value: 'Impuesto Sustitutivo' }
]

export default { DocumentTypes, EconomicActivityTypes, NatureTypes, RegimenTypes }
