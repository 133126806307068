import React, { createContext, ReactNode, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { NotificationModel } from '../models/NotificationModel'

interface NotificationProviderModel {
  notification: NotificationModel | null
  setNotification: (noti: NotificationModel, secondsToLive?: number) => void
}

export interface NotificationProviderProps {
  children: ReactNode
}

const NotificationContext = createContext<NotificationProviderModel>({
  notification: { uid: '', message: '', level: 'info' },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setNotification: (err: NotificationModel) => {
    /* do nothing */
  }
})

export const useNotificationContext = () => useContext(NotificationContext)

export const NotificationProvider: React.SFC<NotificationProviderProps> = ({ children }) => {
  const [notification, _setNotification] = useState(null as NotificationModel | null)

  const setNotification = (noti: NotificationModel) => {
    noti.uid = uuidv4().substring(0, 6)
    _setNotification(noti)
  }

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>{children}</NotificationContext.Provider>
  )
}
